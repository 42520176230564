<script>
import { mapActions, mapState } from "vuex";
import ContactItem from "./ContactItem.vue";
import { processContactsList } from "@/utils/SendCheckInLinkDialog.utils.js";

const STEPS = {
  EXISTING_CONTACTS: "existing-contacts",
  NEW_CONTACT: "new-contact"
};

export default {
  name: "SendCheckInLinkDialog",
  components: {
    ContactItem
  },
  props: {
    currentAppointment: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      STEPS,
      step: STEPS.EXISTING_CONTACTS,
      selectedContact: null,
      newContactType: "sms",
      newContactValue: "",
      isSendingLink: false,
      sendingError: null,
      isSuccessfullySent: false
    };
  },
  computed: {
    ...mapState({
      emails: state => processContactsList(state.patient?.emails),
      phones: state => processContactsList(state.patient?.phones),
      collectlyPatientId: state => state.patient.id,
      externalPatientId: state => state.patientId
    }),
    canSend() {
      if (this.isSendingLink) {
        return false;
      }

      if (this.step === STEPS.EXISTING_CONTACTS) {
        return !!this.selectedContact;
      }

      if (this.newContactType === "email") {
        return this.isValidEmail(this.newContactValue);
      }

      return !!this.newContactValue;
    },
    hasContacts() {
      return !!this.emails.length || !!this.phones.length;
    }
  },
  methods: {
    ...mapActions([
      "showSendCheckInLinkDialog",
      "sendCheckInLink",
      "getAppointmentData",
      "addPhoneToPatient",
      "addEmailToPatient",
      "getPatient"
    ]),
    closeDialog() {
      this.showSendCheckInLinkDialog(false);

      if (!this.isSuccessfullySent) return;

      this.getPatient({ patientExternalId: this.externalPatientId });
    },
    formatPhoneNumber(event) {
      event.target.value = event.target.value.replace(/[^0-9]/g, "");
      this.newContactValue = event.target.value;
    },
    isValidEmail(email) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(email);
    },
    async createNewContact() {
      if (this.step !== STEPS.NEW_CONTACT) return undefined;

      const addContactMethod =
        this.newContactType === "sms"
          ? this.addPhoneToPatient
          : this.addEmailToPatient;

      const addContactResp = await addContactMethod({
        patientId: this.collectlyPatientId,
        email:
          this.newContactType === "email" ? this.newContactValue : undefined,
        phone: this.newContactType === "sms" ? this.newContactValue : undefined
      });

      if (!addContactResp) return undefined;

      return addContactResp.id;
    },
    async handleSendLinkClick() {
      if (!this.canSend) return;

      this.isSendingLink = true;
      this.isSuccessfullySent = false;
      this.sendingError = null;

      try {
        const newContactId = await this.createNewContact();

        const existingContactChannel = this.selectedContact?.email
          ? "email"
          : "sms";

        const existingContactPayload = {
          checkinId: this.currentAppointment.check_in.id,
          contact_id: this.selectedContact?.id,
          channel: existingContactChannel
        };

        const newContactPayload = {
          checkinId: this.currentAppointment.check_in.id,
          contact_id: newContactId,
          channel: this.newContactType
        };

        await this.sendCheckInLink(
          this.step === STEPS.NEW_CONTACT
            ? newContactPayload
            : existingContactPayload
        );

        this.isSuccessfullySent = true;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        this.sendingError = error?.error_text
          ? error.error_text
          : "Could not send the link. Please try again later.";
      } finally {
        this.isSendingLink = false;
      }
    }
  }
};
</script>

<template>
  <div class="wrapper">
    <div class="base">
      <div class="close-icon-wrapper" @click="closeDialog">
        <font-awesome-icon icon="times" size="1x"></font-awesome-icon>
      </div>

      <div class="padding-xl">
        <div class="heading-s">Link to digital check-in</div>

        <div
          v-if="step === STEPS.EXISTING_CONTACTS"
          :class="{
            'margin-top-xl-ppNew': true,
            disabledFormState: isSendingLink || isSuccessfullySent
          }"
        >
          <div class="display-flex justify-space-between">
            <div class="text-m-semibold">Select a contact</div>

            <button
              v-if="hasContacts"
              type="button"
              class="tertiary-button text-m-semibold"
              @click="step = STEPS.NEW_CONTACT"
            >
              Add a new contact
            </button>
          </div>

          <div
            v-if="hasContacts"
            class="margin-top-m-ppNew display-flex flex-column gap-s-ppNew contacts-list"
          >
            <template v-if="phones && !!phones.length">
              <ContactItem
                v-for="phone in phones"
                :contact="phone"
                :key="phone.id"
                :selectedContactId="selectedContact?.id"
                @selectContact="selectedContact = phone"
              />
            </template>

            <template v-if="emails && !!emails.length">
              <ContactItem
                v-for="email in emails"
                :contact="email"
                :key="email.id"
                :selectedContactId="selectedContact?.id"
                @selectContact="selectedContact = email"
              />
            </template>
          </div>

          <div
            v-if="!hasContacts"
            class="margin-top-m-ppNew padding-x-xl-ppNew padding-y-l-ppNew display-flex flex-column justify-center align-center gap-xs-ppNew bg-grey-5-ppNew border-radius-2xs-ppNew grey-100-ppNew no-contacts-note"
          >
            <div class="text-m-medium">
              No contacts available for this patient
            </div>
            <button
              type="button"
              class="tertiary-button text-m-semibold"
              @click="step = STEPS.NEW_CONTACT"
            >
              Send to new contact
            </button>
          </div>
        </div>

        <div
          v-if="step === STEPS.NEW_CONTACT"
          :class="{
            'margin-top-xl-ppNew': true,
            disabledFormState: isSendingLink || isSuccessfullySent
          }"
        >
          <button
            class="tertiary-button text-m-semibold display-flex gap-xs-ppNew align-center"
            type="button"
            @click="
              step = STEPS.EXISTING_CONTACTS;
              newContactValue = null;
            "
          >
            <svg
              width="6"
              height="10"
              viewBox="0 0 6 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.78033 0.209209C6.07322 0.488155 6.07322 0.940416 5.78033 1.21936L1.81066 5L5.78033 8.78064C6.07322 9.05958 6.07322 9.51184 5.78033 9.79079C5.48744 10.0697 5.01256 10.0697 4.71967 9.79079L0.21967 5.50508C-0.0732233 5.22613 -0.0732233 4.77387 0.21967 4.49492L4.71967 0.209209C5.01256 -0.0697365 5.48744 -0.0697365 5.78033 0.209209Z"
                fill="#4C5FED"
              />
            </svg>
            Back to contacts list
          </button>

          <div
            class="margin-top-m-ppNew padding-s-ppNew display-flex flex-column gap-s-ppNew bg-grey-5-ppNew border-radius-2xs-ppNew"
          >
            <form @submit.prevent="handleSendLinkClick()">
              <div>
                <div class="display-flex gap-xs-ppNew">
                  <input
                    class="radio-button"
                    type="radio"
                    name="contact"
                    id="new-contact"
                    v-model="newContactType"
                    value="sms"
                    @change="newContactValue = ''"
                  />
                  <label
                    class="text-m-semibold cursor-pointer"
                    for="new-contact"
                    >Phone</label
                  >
                </div>

                <input
                  v-if="newContactType === 'sms'"
                  @input="formatPhoneNumber"
                  class="text-m text-input"
                  type="text"
                  placeholder="0000000000"
                />
              </div>

              <div class="margin-top-s-ppNew">
                <div class="display-flex gap-xs-ppNew">
                  <input
                    class="radio-button"
                    type="radio"
                    name="contact"
                    id="email-contact"
                    v-model="newContactType"
                    value="email"
                    @change="newContactValue = ''"
                  />
                  <label
                    class="text-m-semibold cursor-pointer"
                    for="email-contact"
                    >Email</label
                  >
                </div>

                <input
                  v-if="newContactType === 'email'"
                  class="text-m text-input"
                  type="text"
                  placeholder="patient@domain.com"
                  v-model="newContactValue"
                />
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="margin-top-m-ppNew red-120-ppNew text-s">
        {{ sendingError }}
      </div>

      <div
        v-if="isSuccessfullySent"
        class="margin-top-auto-ppNew padding-top-l-ppNew green-120-ppNew text-l-semibold display-flex gap-xs-ppNew justify-center align-center success-message"
      >
        <div class="success-icon-wrapper">
          <svg
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M13.2372 4.32428C13.6103 4.7314 13.5828 5.36397 13.1757 5.73716L6.46992 11.8841L3.29289 8.70711C2.90237 8.31659 2.90237 7.68342 3.29289 7.2929C3.68342 6.90237 4.31658 6.90237 4.70711 7.2929L6.53008 9.11587L11.8243 4.26285C12.2314 3.88966 12.864 3.91716 13.2372 4.32428Z"
              fill="#ffffff"
            />
          </svg>
        </div>

        Link is sent
      </div>

      <div
        v-if="!isSuccessfullySent"
        class="margin-top-auto-ppNew padding-top-l-ppNew display-flex justify-end"
      >
        <button
          class="primary-button display-flex gap-2xs-ppNew align-center"
          type="button"
          @click="handleSendLinkClick()"
          :disabled="!canSend"
        >
          <font-awesome-icon
            v-if="isSendingLink"
            icon="circle-notch"
            spin
            size="1x"
            class="blue-100-ppNew"
          ></font-awesome-icon>
          {{ isSendingLink ? "Sending..." : "Send link" }}
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.wrapper {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 100;
  animation: fadeIn 0.7s 0.25s forwards;
  opacity: 0;
}

.disabledFormState {
  pointer-events: none;
  user-select: none;
  opacity: 0.4;
}

.base {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 48px;
  width: 720px;
  min-height: 520px;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background-color: white;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
  transition: min-height 0.5s ease, width 0.5s ease;
}

.close-icon-wrapper {
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
  border-radius: 50%;
  padding: 5px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: $grey-100;
  background: $grey-5;
  transition: color 0.2s, background-color 0.2s;

  &:hover {
    color: $blue-100;
    background: $grey-10;
  }
}

.contacts-list {
  max-height: 400px;
  overflow-y: auto;
}

.radio-button {
  accent-color: $blue-100;
}

.text-input {
  margin-top: $gap-xs-ppNew;
  margin-left: $gap-l-ppNew;
  padding: 10px $gap-s-ppNew;
  min-width: 280px;
  border: 1px solid $grey-50;
  border-radius: 6px;
  font-family: inherit;
}

@keyframes successMessage {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.success-message {
  animation: successMessage 1.2s forwards;
}

.success-icon-wrapper {
  width: 24px;
  height: 24px;
  background: $green-100;
  border-radius: 100%;
  padding: 2px;
}

.no-contacts-note {
  min-height: 256px;
}
</style>
