<script>
import moment from "moment";

export default {
  name: "PatientCheckInActions",
  props: {
    appointment: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      shouldShowAllActions: false
    };
  },
  computed: {
    actions() {
      return this.appointment.check_in_actions || [];
    },
    shouldShowNote() {
      if (!this.actions.length) {
        return false;
      }

      const lastAction = this.actions
        .slice()
        .sort((a, b) => b.created_at - a.created_at)[0];

      const tenMinutesAgo = moment()
        .subtract(10, "minutes")
        .valueOf();

      const shouldShow = !moment(lastAction.created_at).isBefore(tenMinutesAgo);

      return shouldShow;
    }
  },
  methods: {
    toggleShowAllActions() {
      this.shouldShowAllActions = !this.shouldShowAllActions;
    }
  }
};
</script>

<template>
  <div class="padding-s-ppNew bg-grey-5-ppNew border-radius-2xs-ppNew">
    <div
      v-if="
        appointment.check_in_display_status === 'in_progress' ||
          appointment.check_in_display_status === 'completed' ||
          appointment.check_in_display_status === 'cancelled'
      "
      class="text-m-semibold grey-100-ppNew"
    >
      {{
        actions && actions.length
          ? "Patient completed the following actions during digital check-in:"
          : "Patient has not completed any actions during digital check-in."
      }}
    </div>

    <div v-else class="text-m-semibold grey-100-ppNew">
      Patient has not started digital check-in yet
    </div>

    <ul
      v-if="actions && actions.length"
      class="actions margin-top-2xs-ppNew padding-left-s-ppNew grey-100-ppNew"
    >
      <li
        class="listItem"
        v-for="(action, index) in shouldShowAllActions
          ? actions
          : actions.slice(0, 4)"
        :key="index"
      >
        <span>{{ action.name }}</span>

        <span v-if="typeof action.value === 'string'" class="text-m-semibold margin-left-3xs-ppNew">{{
          action.value
        }}</span>

        <span v-if="typeof action.tooltip === 'string'" class="margin-left-3xs-ppNew">
          <div class="customTooltip">
            <font-awesome-icon icon="question-circle" class="blue-100-ppNew" />

            <div class="tooltipContent">
              <div class="text-m">
                {{
                  action.tooltip
                }}
              </div>
            </div>
          </div>
        </span>

        <span v-if="Array.isArray(action.tooltip)" class="margin-left-3xs-ppNew">
          <div class="customTooltip">
            <font-awesome-icon icon="question-circle" class="blue-100-ppNew" />

            <div class="tooltipContent">
              <div class="text-m">
                {{
                  action.tooltip.length &&
                    action.tooltip
                      .map(s => s[0].toUpperCase() + s.slice(1))
                      .join(", ")
                }}
              </div>
            </div>
          </div>
        </span>
      </li>

      <div
        v-if="actions.length > 4"
        @click="toggleShowAllActions()"
        class="margin-top-3xs-ppNew text-m-semibold blue-100-ppNew toggleBtn"
      >
        Show {{ shouldShowAllActions ? "less" : "more" }}
      </div>
    </ul>

    <div v-if="shouldShowNote" class="margin-top-xs-ppNew text-s grey-70-ppNew">
      <span class="text-s-semibold">Note: </span> changes to the patient
      check-in may not be reflected in the EHR immediately
    </div>
  </div>
</template>

<style scoped lang="scss">
.actions {
  display: flex;
  flex-direction: column;
  gap: $gap-4xs-ppNew;

  li {
    position: relative;
    font-weight: 400;

    &::before {
      content: "";
      position: absolute;
      left: -16px;
      top: 8px;
      width: 4px;
      height: 4px;
      background: $grey-50;
      border-radius: 50%;
    }
  }
}

.toggleBtn {
  cursor: pointer;
  transition: color 0.2s ease;
  user-select: none;
  &:active {
    color: $blue-180;
  }
}

.listItem {
  position: relative;
}

.customTooltip {
  display: inline-block;
  cursor: pointer;

  .tooltipContent {
    width: 200px;
    transition: visibility 0.2s, opacity 0.2s;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    padding: $gap-xs-ppNew;
    background: $white-100;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1;
  }

  &:hover {
    .tooltipContent {
      visibility: visible;
      opacity: 1;
    }
  }
}
</style>
