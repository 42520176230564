<template>
  <div class="modal-container">
    <div class="modal-fullscreen">
      <div class="modal-content">
        <ValidationObserver ref="observer1" v-slot="{ passes }">
          <div class="modal-header">
            <div class="modal-title">
              {{
                dialogMode === "add" ? "Store new card on file" : "Charge card"
              }}
            </div>
          </div>
          <div class="modal-body" :class="{ complete }">
            <b-field label="Card name" v-if="dialogMode !== 'charge'">
              <b-input
                placeholder="Type your message here..."
                v-model="cardName"
              />
            </b-field>
            <b-field label="Payment amount" v-if="dialogMode === 'charge'">
              <b-input
                placeholder="0.00"
                type="number"
                v-model.number="paymentAmount"
                min="1"
                step="0.01"
                required="true"
              />
            </b-field>
            <div class="block" v-if="dialogMode === 'charge'">
              <b-radio
                v-model="paymentType"
                name="paymentType"
                native-value="copay"
              >
                Copay
              </b-radio>
              <b-radio
                v-model="paymentType"
                name="paymentType"
                native-value="payment"
              >
                Apply towards Collectly balance
              </b-radio>
            </div>
            <b-field
              label="Location"
              v-if="dialogMode === 'charge' && paymentType === 'copay'"
              :message="{
                'The field is required': !selectedLocation
              }"
            >
              <b-autocomplete
                :data="filteredLocations"
                ref="locationAutocomplete"
                placeholder="Start typing a location name..."
                :custom-formatter="formatLocation"
                v-model="locationName"
                :open-on-focus="true"
                @select="option => (selectedLocation = option)"
                group-field="providerGroup"
                group-options="locations"
                @typing="searchLocations"
                :loading="isLocationFetching"
              >
              </b-autocomplete>
            </b-field>
            <b-field label="Payment method" v-if="dialogMode === 'charge'">
              <b-autocomplete
                :data="patientCards"
                ref="cardAutocomplete"
                placeholder="Select Patient Card"
                :custom-formatter="formatCard"
                :open-on-focus="true"
                clearable
                @select="option => (selectedCard = option)"
              >
                <template slot-scope="props">
                  <div class="card-template">
                    <span>{{ props.option.name }}</span>
                    <span v-if="props.option.card_type === 'bank_account'" class=""
                      >•••• {{ props.option.bank_account_mask }}</span
                    >
                    <span v-if="props.option.card_type !== 'bank_account'" class=""
                    ><i
                        :class="'card-icon card-' + props.option.cardIconClass"
                    />
                      •••• {{ props.option.last4 }}</span
                    >
                    <span class="" v-if="props.option.card_type !== 'bank_account'">
                      <span class="">Exp</span>
                      {{ props.option.exp_month }}/{{
                        props.option.exp_year.toString().substr(2)
                      }}
                    </span>
                    <span v-if="props.option.auto_charge_enabled">
                      <i
                        class="fa fa-credit-card fa-success"
                        uib-tooltip="Auto charge consent given"
                        tooltip-class="tooltip-wide"
                      />
                    </span>
                    <span v-if="!props.option.auto_charge_enabled">
                      <i
                        class="fa fa-credit-card fa-danger"
                        uib-tooltip="No auto charge consent"
                        tooltip-class="tooltip-wide"
                      />
                    </span>
                    <span class="is-size-7"
                      >Added:
                      {{ props.option.created_at | formatDate("l") }}
                    </span>
                    <span class="is-size-7">{{ props.option.status }}</span>
                  </div>
                </template>
              </b-autocomplete>
              <p class="addon-right">
                <b-button
                  class="button is-primary"
                  :disabled="!selectedCard"
                  @click="clearCard()"
                >
                  Clear
                </b-button>
              </p>
            </b-field>
            <div v-show="!selectedCard">
              <b-field label="Card number">
                <card-number
                  class="stripe-element card-number control"
                  ref="cardNumber"
                  :stripe="stripeKey"
                  :options="stripeOptions"
                  @change="number = $event.complete"
                />
              </b-field>
              <b-field label="Expires">
                <card-expiry
                  class="stripe-element card-expiry control"
                  ref="cardExpiry"
                  :stripe="stripeKey"
                  :options="stripeOptions"
                  @change="expiry = $event.complete"
                />
              </b-field>
              <b-field label="CVC">
                <card-cvc
                  class="stripe-element card-cvc control"
                  ref="cardCvc"
                  :stripe="stripeKey"
                  :options="stripeOptions"
                  @change="cvc = $event.complete"
                />
              </b-field>
              <b-field>
                <b-checkbox v-model="allowAutodebit">
                  Store card for auto-debit
                </b-checkbox>
              </b-field>
              <b-field>
                <b-checkbox v-model="isDebitCard">
                  This is a Debit card
                </b-checkbox>
              </b-field>
            </div>
          </div>
          <div class="modal-footer">
            <div v-if="error" class="block has-text-danger">
              {{ error }}
            </div>
            <div
              v-if="transactionFeeLabel || isFetchingTransactionFee"
              class="transactionFeeMessage display-flex gap-3xs-ppNew text-s grey-100-ppNew margin-bottom-s-ppNew"
            >
              <div
                v-if="isFetchingTransactionFee"
                class="display-flex align-center gap-2xs-ppNew loadingContainer"
              >
                <font-awesome-icon
                  icon="circle-notch"
                  spin
                  class="grey-100-ppNew text-l"
                ></font-awesome-icon>
              </div>

              <span v-if="!isFetchingTransactionFee" class="text-s-medium">
                {{ transactionFeeLabel }}
                <span v-if="transactionFeeAmount">{{
                  transactionFeeAmount | toUSD
                }}</span>
              </span>
            </div>
            <div class="buttons">
              <button
                class="button is-primary"
                :disabled="
                  loading ||
                    (dialogMode === 'charge' &&
                      paymentType === 'copay' &&
                      !selectedLocation)
                "
                @click="passes(submit)"
              >
                {{ submitButtonName }} <span v-if="paymentAmount && dialogMode !== 'add'">&nbsp;({{paymentAmount + (transactionFeeAmount ?? 0) | toUSD }})</span>
              </button>
              <button
                class="button is-danger"
                :disabled="loading"
                @click="cancel()"
              >
                Cancel
              </button>
            </div>
          </div>
        </ValidationObserver>
      </div>
    </div>
    <div class="modal-backdrop" />
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";
library.add(faSignInAlt);

import { mapActions, mapState } from "vuex";
import { ValidationObserver } from "vee-validate";
import BField from "buefy/src/components/field/Field";
import BInput from "buefy/src/components/input/Input";
import {
  CardNumber,
  CardExpiry,
  CardCvc,
  createToken
} from "vue-stripe-elements-plus";
import BCheckbox from "buefy/src/components/checkbox/Checkbox";
import { stripeKey, stripeOptions } from "../constants";
import toast from "../mixins/toast";
import _orderBy from "lodash/orderBy";
import _debounce from "lodash/debounce";

export default {
  name: "AddCard",
  components: {
    BCheckbox,
    BInput,
    ValidationObserver,
    BField,
    CardNumber,
    CardExpiry,
    CardCvc
  },
  mixins: [toast],
  mounted() {},
  props: {
    dialogMode: {
      type: String,
      default: "add"
    }
  },
  data() {
    return {
      loading: false,
      cardName: "Sidebar Stored Card",
      paymentAmount: null,
      paymentType: "payment",
      allowAutodebit: false,
      isDebitCard: false,
      transactionFeeAmount: null,
      nonCCConvenienceFeeChargingIsDisabled: false,
      hsaConvenienceFeeChargingIsDisabled: false,
      transactionFeeLabel: null,
      isFetchingTransactionFee: false,
      error: null,
      token: null,
      complete: false,
      number: false,
      expiry: false,
      cvc: false,
      stripe: null,
      stripeKey: stripeKey,
      stripeOptions: stripeOptions,
      selectedCard: null,
      selectedLocation: null,
      isLocationFetching: false,
      locationName: null
    };
  },
  computed: {
    ...mapState({
      filteredLocations(state) {
        this.isLocationFetching = false;
        if (!state.locations) return;
        const groups = state.locations.reduce((acc, location) => {
          const pgName =
            location?.data?.provider_group_name || "Without provider group";
          if (!acc[pgName]) {
            acc[pgName] = [];
          }
          acc[pgName].push(location);
          return acc;
        }, {});
        return _orderBy(
          Object.keys(groups).map(pgName => {
            return { providerGroup: pgName, locations: groups[pgName] };
          }),
          "providerGroup"
        );
      },
      patient: state => state.patient,
      patientCards: state => state.patientCards,
      accountBalance: state => state.accountBalance,
    }),
    submitButtonName() {
      return this.dialogMode === "add"
        ? "Store Card"
        : this.allowAutodebit
        ? "Charge & Store Card"
        : "Charge";
    }
  },
  created() {
    if (this.dialogMode === "charge" && this.accountBalance <= 0) {
      this.paymentType = "copay";
    }
    if (this.dialogMode === "charge" && this.accountBalance > 0) {
      this.paymentAmount = this.accountBalance;
    }

    this.getPatientClient().then(client => {
      this.nonCCConvenienceFeeChargingIsDisabled =
        !!client.meta.non_cc_convenience_fee_charging_is_disabled ?? false;
      this.hsaConvenienceFeeChargingIsDisabled =
          !!client.meta.hsa_convenience_fee_charging_is_disabled ?? false;
    });

    this.loadCards();
    this.getLocations();
  },
  methods: {
    ...mapActions([
      "showAddCardDlg",
      "addCard",
      "loadCards",
      "storeCard",
      "chargeCard",
      "getLocations",
      "getConvenienceFee",
      "getPatientClient"
    ]),
    submit() {
      this.loading = true;
      if (this.selectedCard) {
        this.chargeFromSelectedCard();
      } else {
        this.chargeFromNewCard();
      }
    },
    chargeFromNewCard() {
      createToken()
        .then(event => {
          if (event.error) {
            this.error = event.error.message;
            this.loading = false;
            return;
          }
          let payload = {
            user_token: event.token.id,
            exp_month: event.token.card.exp_month,
            exp_year: event.token.card.exp_year,
            last4: event.token.card.last4,
            type: event.token.card.brand.toLowerCase(),
            funding: event.token.card.funding,
            country: event.token.card.country,
            name: this.cardName,
            payment_type: this.paymentType,
            auto_charge_enabled: this.allowAutodebit,
            is_credit_card: !this.isDebitCard
          };
          if (this.dialogMode === "charge") {
            if (this.paymentAmount > 0) {
              payload.amount = this.paymentAmount;
            }
            payload.one_time = !this.allowAutodebit;
            if (this.paymentType === "copay" && this.selectedLocation) {
              payload.location_id = parseInt(this.selectedLocation.id);
            }
          }
          this.storeCard(payload)
            .then(card => {
              if (payload.amount > 0 && !payload.one_time) {
                this.chargeCard({
                  card_id: card.id,
                  amount: payload.amount,
                  type: payload.payment_type
                })
                  .then(() => {
                    this.$toast.success("Payment charged.");
                    this.showAddCardDlg(false);
                    this.loading = false;
                  })
                  .catch(r => {
                    this.error = r.response.data.error;
                    this.loading = false;
                  });
              } else {
                this.showAddCardDlg(false);
                this.loading = false;
              }
            })
            .catch(r => {
              this.error = r.response.data.error;
              this.loading = false;
            });
        })
        .catch(err => {
          this.error = err.error.message;
          this.loading = false;
        });
    },
    chargeFromSelectedCard() {
      const payload = {
        card_id: this.selectedCard.id,
        amount: this.paymentAmount,
        type: this.paymentType
      };
      if (this.paymentType === "copay" && this.selectedLocation) {
        payload.location_id = parseInt(this.selectedLocation.id);
      }
      this.chargeCard(payload)
        .then(() => {
          this.$toast.success("Payment charged.");
          this.showAddCardDlg(false);
          this.loading = false;
        })
        .catch(r => {
          this.error = r.response.data.error;
          this.loading = false;
        });
    },
    cancel() {
      this.showAddCardDlg(false);
    },
    updateElements() {
      this.complete = this.number && this.expiry && this.cvc;

      // field completed, find field to focus next
      if (this.number) {
        if (!this.expiry) {
          this.$refs.cardExpiry.focus();
        } else if (!this.cvc) {
          this.$refs.cardCvc.focus();
        }
      } else if (this.expiry) {
        if (!this.cvc) {
          this.$refs.cardCvc.focus();
        } else if (!this.number) {
          this.$refs.cardNumber.focus();
        }
      }
      // no focus magic for the CVC field as it gets complete with three
      // numbers, but can also have four
    },
    formatCard(c) {
      if (c.card_type === 'bank_account') {
        return c.name + " •••• " + c.bank_account_mask;
      }

      return (
        c.name +
        "   •••• " +
        c.last4 +
        " [" +
        c.exp_month +
        "/" +
        c.exp_year.toString().substr(2) +
        "] " +
        c.status
      );
    },
    formatLocation(l) {
      if (!l) return;
      return "#" + l.external_id + " " + l.name;
    },
    clearCard() {
      this.$refs.cardAutocomplete.setSelected(null);
      this.$refs.cardAutocomplete.newValue = "";
    },
    searchLocations: _debounce(function(name) {
      this.isLocationFetching = true;
      this.selectedLocation = null;
      this.getLocations(name);
    }, 500),
    calcTransactionFee: _debounce(function(amount) {
      this.isFetchingTransactionFee = !!this.transactionFeeLabel;
      this.transactionFeeLabel = null;
      this.transactionFeeAmount = null;

      if (!amount || this.dialogMode === "add") {
        this.isFetchingTransactionFee = false;
        return;
      }

      this.getConvenienceFee(amount)
        .then(fee => {
          let isDebitCard = this.isDebitCard;
          let isACH = false;
          let isHSA = false;

          if (this.selectedCard) {
            isACH = this.selectedCard.card_type === "bank_account";

            if (!isACH) {
              isDebitCard = this.selectedCard.is_credit_card === false;
              isHSA = this.selectedCard.is_hsa_payment_method;
            }
          }

          const transactionFeeNonACHAmount =
            fee && fee.convenience_fee_amount_non_ach
              ? parseFloat(fee.convenience_fee_amount_non_ach)
              : null;

          const transactionFeeACHAmount =
              fee && fee.convenience_fee_amount_ach
                  ? parseFloat(fee.convenience_fee_amount_ach)
                  : null;

          if (isACH) {
            if (transactionFeeACHAmount) {
              this.transactionFeeAmount = transactionFeeACHAmount;
              this.transactionFeeLabel = `Includes transaction fee of`;
            } else if (transactionFeeNonACHAmount) {
              this.transactionFeeLabel = `No transaction fee for ACH payments`;
            }
            return;
          }

          if (
            isHSA &&
            transactionFeeNonACHAmount &&
            this.hsaConvenienceFeeChargingIsDisabled
          ) {
            this.transactionFeeAmount = null;
            this.transactionFeeLabel = "No transaction fee for HSA/FSA cards";
            return;
          }

          if (
            isDebitCard &&
            transactionFeeNonACHAmount &&
            this.nonCCConvenienceFeeChargingIsDisabled
          ) {
            this.transactionFeeAmount = null;
            this.transactionFeeLabel = "No transaction fee for debit cards";
            return;
          }

          if (transactionFeeNonACHAmount) {
            this.transactionFeeAmount = transactionFeeNonACHAmount;
            this.transactionFeeLabel = `Includes transaction fee of`;
          }
        })
        .catch(() => {
          this.transactionFeeAmount = null;
        })
        .finally(() => {
          this.isFetchingTransactionFee = false;
        });
    }, 300)
  },
  watch: {
    number() {
      this.updateElements();
    },
    expiry() {
      this.updateElements();
    },
    cvc() {
      this.updateElements();
    },
    paymentAmount(amount) {
      this.calcTransactionFee(amount);
    },
    isDebitCard() {
      this.calcTransactionFee(this.paymentAmount);
    },
    selectedCard() {
      this.calcTransactionFee(this.paymentAmount);
    }
  }
};
</script>

<style scoped lang="scss">
.stripe-element {
  box-shadow: inset 0 0.0625em 0.125em rgba(0, 0, 0, 0.05);
  max-width: 100%;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  padding: 0.5rem;
}
</style>
