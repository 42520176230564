// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/checkmark.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/alert.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../assets/caret.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, ".status[data-v-2f705dc3]{padding:2px 8px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;border:1px solid currentColor;border-radius:6px;text-transform:uppercase;font-size:10px;font-weight:700;line-height:12px;letter-spacing:.15em}.status.digitally_unreachable[data-v-2f705dc3]{color:#c13854}.status.completed[data-v-2f705dc3]{color:#009a71}.status.link_sent[data-v-2f705dc3]{color:#009a71;background:#c8fde5;border-color:#c8fde5}.status.cancelled[data-v-2f705dc3],.status.link_not_sent[data-v-2f705dc3]{color:#4c5773;background:#edeef1;border-color:#edeef1}.container .block .line[data-v-2f705dc3]{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-bottom:.5em}.apptsHeading.is-success[data-v-2f705dc3]{color:#009a71}.apptsHeading.is-error[data-v-2f705dc3]{color:#c13854}.icon[data-v-2f705dc3]{display:inline-block;background-repeat:no-repeat;background-position:50%;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.alert-icon[data-v-2f705dc3]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.caret-icon[data-v-2f705dc3]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}.caret-icon.right[data-v-2f705dc3]{-webkit-transform:rotate(180deg);transform:rotate(180deg)}.controlBtn[data-v-2f705dc3]{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding:0;border:none;background:none;cursor:pointer;-webkit-transition:.2s ease;transition:.2s ease}.controlBtn.right[data-v-2f705dc3]{margin-left:auto}", ""]);
// Exports
module.exports = exports;
