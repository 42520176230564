export function processContactsList(contacts) {
  if (!contacts || !contacts.length) return [];

  return contacts
    .slice()
    .filter(i => i.enabled && !i.blocked)
    .sort((a, b) => {
      if (a.priority === "primary") return -1;
      if (b.priority === "primary") return 1;
      return 0;
    });
}
