<template>
  <div class="container">
    <div class="block">
      <div class="margin-bottom-xs-ppNew">
        <span>
          <font-awesome-icon
            icon="sync"
            v-if="patients"
            @click="resetPatientSelect()"
          />
          <a @click="openPatientInApp()" class="text-l-semibold">
            {{ patient.first_name }} {{ patient.last_name }}</a
          >
        </span>
        <span class="margin-left-xs-ppNew text-m-regular grey-100-ppNew">
          {{ patient.date_of_birth | formatDate("MMM D, YYYY") }}
        </span>
      </div>

      <span class="line">
        <span class="line-subtitle">Current balance</span>
        <span class="line-value">
          <b-tooltip
            :label="
              `${patient_ecw_pending_balance_formatted} not assigned to patient`
            "
            type="is-white"
            multilined
            size="is-small"
            position="is-left"
          >
            <font-awesome-icon
              v-if="
                patient &&
                  patient.data &&
                  toNumber(patient.data.ecw_pending_balance) > 0
              "
              icon="exclamation-triangle"
              class="balance-warning"
            />
          </b-tooltip>
          <span>
            {{ patient.current_balance | toUSD }}
          </span>
        </span>
      </span>
      <span class="line" v-if="patient.family_group_role !== 'independent'">
        <span class="line-subtitle">Family balance</span>
        <span class="line-value">
          {{ patient.family_balance | toUSD }}
        </span>
      </span>
      <span class="line">
        <span class="line-subtitle">Last payment</span>
        <span class="line-value">
          {{ patient.last_payment_time | formatDate("ll") }}
        </span>
      </span>
      <span class="line">
        <span class="line-subtitle">Unallocated</span>
        <span class="line-value">
          {{ patient.unapplied | toUSD }}
        </span>
      </span>
      <span class="line" v-if="!isPatientIntakeOnlyFeatureEnabled">
        <span class="line-subtitle">Collectly status</span>
        <span class="line-value">
          <label>{{ billingCycle.status || "No cycle yet" }}</label>
        </span>
      </span>
      <span
        v-if="patient.paused && patient.pause_reason.indexOf('external') !== -1"
      >
        <span class="line u-mt2" style="display: inherit;">
          <font-awesome-icon
            icon="exclamation-triangle"
            class="balance-warning icon-gap"
          />
          On hold -
          <template v-if="patient && patient.data">
            {{
              patient.data.pause_description ||
                "Patient is paused due to patient settings in billing system"
            }}
          </template>
        </span>
      </span>
    </div>

    <div class="block" v-if="!isPatientIntakeOnlyFeatureEnabled">
      <!--  -->
      <span class="block-title">Current billing cycle</span>
      <span class="line">
        <span class="line-subtitle">Paused by chat</span>
        <span class="line-value">
          <label>{{ billingCycle.paused ? "Yes" : "No" }}</label>
        </span>
      </span>
      <span class="line">
        <span class="line-subtitle">Rule</span>
        <span class="line-value text-right">{{
          rule.name || "Not assigned"
        }}</span>
      </span>
      <span class="line">
        <span class="line-subtitle">Step</span>
        <span class="line-value">
          {{ rule.name ? (billingCycle.workflow_step || 0) + 1 : 0 }} of
          {{ (rule.steps || []).length }}
        </span>
      </span>
      <span class="line">
        <span class="line-subtitle">Next action</span>
        <span class="line-value" v-if="rule.name">
          {{ nextAction }} on
          {{ billingCycle.workflow_paused_before | formatDate("l") }}
        </span>
        <span class="line-value" v-else>-</span>
      </span>
    </div>

    <div
      class="block"
      v-if="patient.next_appointments && patient.next_appointments.length > 0"
    >
      <AppointmentsSlider
        :appointments="patient.next_appointments"
        :isAppointmentPollingAllowed="isVisible"
      ></AppointmentsSlider>
    </div>

    <div class="block">
      <span class="block-title">Payments</span>
      <a
        v-bind:href="webappUrl + '/patients/' + (patient.guarantor_patient?.id || patient.id) + '/payments'"
        target="_blank"
        class="text-m-semibold"
        >View history</a
      >
      <span class="vertical-separator">|</span>
      <a
        @click="chargePayment()"
        :class="{
          'is-disabled': currentUserIsReadOnly,
          'text-m-semibold': true
        }"
        >Take payment</a
      >

      <div class="margin-top-2xs-ppNew display-flex align-center gap-3xs-ppNew">
        <font-awesome-icon
          v-if="isLoadingPos"
          icon="circle-notch"
          spin
          size="1x"
          class="blue-120-ppNew"
        ></font-awesome-icon>
        <div :title="disablePosButtonText">
          <a
            @click="handleTakePaymentWithPosClick()"
            :class="{
              'is-disabled':
                !isPosFeatureEnabled ||
                terminals.length === 0 ||
                isLoadingPos ||
                currentUserIsReadOnly,
              'text-m-semibold': true
            }"
            >Take payment with POS</a
          >
        </div>
      </div>
    </div>

    <div class="block">
      <span class="block-title">Payment Plan</span>
      <div v-if="!paymentPlan" />

      <p class="line text-m" v-if="paymentPlan">
        <span class="line-subtitle">Status</span>
        <span class="line-value">{{ formatStatus(paymentPlan.status) }}</span>
      </p>
      <p class="line text-m" v-if="paymentPlan">
        <span class="line-subtitle">Installment</span>
        <span class="line-value">{{ paymentPlan.installment | toUSD }}</span>
      </p>
      <p class="line" v-if="paymentPlan">
        <span class="line-subtitle">Remaining</span>
        <span class="line-value">
          {{ paymentPlan.remaining | toUSD }} ({{
            paymentPlan.n_installments - paymentPlan.payments_made
          }}
          payments)
        </span>
      </p>
      <p
        class="line"
        v-if="paymentPlan && paymentPlan.status !== 'not_subscribed'"
      >
        <span class="line-subtitle">Next payment</span>
        <span class="line-value">{{
          paymentPlan.next_payment | formatDate("l")
        }}</span>
      </p>
      <div class="margin-top-xs-ppNew">
        <a
          :class="{
            'is-disabled': currentUserIsReadOnly,
            'text-m-semibold': true
          }"
          @click="editPaymentPlan()"
          >{{ paymentPlan ? "Update plan" : "Create new plan" }}</a
        >
        <span
          v-if="paymentPlan && paymentPlan.status === 'not_subscribed'"
          class="vertical-separator"
          >|</span
        >
        <a
          v-if="paymentPlan && paymentPlan.status === 'not_subscribed'"
          :disabled="
            patient.emails.concat(patient.phones).filter(c => c.enabled)
              .length === 0
          "
          class="text-m-semibold"
          @click="resendConsent()"
          >Resend consent request</a
        >
      </div>
    </div>

    <div class="block">
      <span class="block-title">Contacts</span>
      <span class="line contacts">
        <span class="line-subtitle contact-title">E-mails</span>
        <span class="line-value contact-value">
          <span
            v-for="item in patient.emails"
            :key="item.id"
            class="contact-item"
            :class="{ 'contact-item--disabled': !item.enabled }"
          >
            {{ item.email }}
          </span>
        </span>
      </span>
      <span class="line contacts">
        <span class="line-subtitle contact-title">Phones</span>
        <span class="line-value contact-value">
          <span
            v-for="item in patient.phones"
            :key="item.id"
            class="contact-item"
            :class="{ 'contact-item--disabled': !item.enabled }"
          >
            {{ item.phone }}
          </span>
        </span>
      </span>
    </div>

    <div class="block">
      <p class="line">
        <span class="line-subtitle">Last sync</span>
        <span
          >{{ patient.last_sync | formatDate("lll") }}
          <b-button
            size="is-small"
            :class="{ 'is-disabled': currentUserIsReadOnly }"
            style="vertical-align: baseline"
            @click="resync(patient.id)"
          >
            <font-awesome-icon icon="sync" />
          </b-button>
        </span>
      </p>
      <p class="line u-mt has-text-grey is-size-7">
        If you need to update any information, please do so in your EHR/PM.
        Click resync button for instant update.
      </p>
    </div>

    <PosPaymentDialog v-if="shouldShowPosPaymentDialog" />
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faExclamationTriangle,
  faSignInAlt
} from "@fortawesome/free-solid-svg-icons";
import { orderBy, toNumber } from "lodash";
import { webAppURL } from "@/constants";

library.add(faSignInAlt);
library.add(faExclamationTriangle);

import FontAwesomeIcon from "@fortawesome/vue-fontawesome/src/components/FontAwesomeIcon";
import BButton from "buefy/src/components/button/Button";
import BTooltip from "buefy/src/components/tooltip/Tooltip";
import { mapActions, mapState } from "vuex";
import toast from "../mixins/toast";
import AppointmentsSlider from "./AppointmentsSlider.vue";
import PosPaymentDialog from "./PosPaymentDialog.vue";

export default {
  name: "PatientDetails",
  props: {
    isVisible: {
      type: Boolean,
      required: true
    }
  },
  components: {
    BButton,
    BTooltip,
    FontAwesomeIcon,
    AppointmentsSlider,
    PosPaymentDialog
  },
  mixins: [toast],
  data() {
    return {
      actionNames: {
        digital: "Send Email/SMS",
        voice: "Robocall",
        call: "Manual Call",
        statement: "Send Statement",
        payment: "Charge Patient Card",
        repeat: "Repeat Step",
        delay_dow: "Wait For"
      },
      isLoadingPos: false
    };
  },
  computed: mapState({
    isPosFeatureEnabled: (_, getters) => {
      return getters.isFeatureEnabled("hardware-payments");
    },
    isPatientIntakeOnlyFeatureEnabled: (_, getters) => {
      return getters.isFeatureEnabled("patient-intake-only");
    },
    externalPatientId: state => state.patientId,
    shouldShowPosPaymentDialog: state => state.dialogOpen.posPayment,
    currentUser: state => state.user,
    currentUserIsReadOnly: state => state.is_readonly_user,
    patients: state => state.patients,
    patient: state => state.patient,
    billingCycle: state => state.patient.last_billing_cycle || {},
    rule: state => (state.patient.last_billing_cycle || {}).workflow || {},
    webappUrl: () => webAppURL,
    patientLinkUrl(state) {
      var appRoute =
        // 10 = role "user"
        (state.user.role || 10) > 4
          ? "/patients/" + state.patient.id + "/cards"
          : "/frontdesk/patient/" + state.patient.id;
      return webAppURL + appRoute;
    },
    paymentPlan: state =>
      orderBy(
        (state.patient.payment_plans || []).filter(
          x =>
            x.status === "declined" ||
            x.status === "subscribed" ||
            x.status === "not_subscribed"
        ),
        [
          plan => {
            if (plan.status == "subscribed") {
              return plan.created_at + 1e9;
            }
            return plan.created_at;
          }
        ],
        ["desc"]
      )[0],
    nextAction(state) {
      let bc = state.patient.last_billing_cycle || {};
      let rule = bc.workflow || {};
      if (bc.status === "payment_plan") {
        return '"Charge card"';
      }
      if (bc.workflow_finished || bc.end_date) return "–⁠ (Finished)";

      return (
        '"' +
        this.actionNames[
          ((rule.steps || [])[bc.workflow_step || 0] || {}).action
        ] +
        '"'
      );
    },
    patient_ecw_pending_balance_formatted(state) {
      const balance =
        state.patient && state.patient.data
          ? Number(state.patient.data.ecw_pending_balance)
          : undefined;

      if (balance === undefined) {
        return "";
      }

      return this.$options.filters.toUSD(balance);
    },
    disablePosButtonText() {
      if (this.currentUserIsReadOnly) {
        return "You don't have permission to use this feature";
      }

      if (!this.isPosFeatureEnabled) {
        return "'hardware-payments' feature should be enabled";
      }

      if (this.terminals.length === 0) {
        return "No terminals available. Please add one in the settings";
      }

      return null;
    },
    terminals(state) {
      return state.terminals || [];
    }
  }),
  created() {
    this.isLoadingPos = true;

    Promise.all([
      this.getCurrentCompanyFeatures(),
      this.getClientTerminals()
    ]).finally(() => {
      this.isLoadingPos = false;
    });
  },
  methods: {
    ...mapActions([
      "resync",
      "showEditPlanDlg",
      "showChargeDlg",
      "showRequestConsentDlg",
      "loadConsents",
      "resendConsentRequest",
      "resetPatientSelect",
      "showPosPaymentDialog",
      "getCurrentCompanyFeatures",
      "getClientTerminals",
      "getPatient"
    ]),
    handleTakePaymentWithPosClick() {
      if (!this.isPosFeatureEnabled) return;

      this.getPatient({ patientExternalId: this.externalPatientId }).then(
        () => {
          this.showPosPaymentDialog(true);
        }
      );
    },
    openPatientInApp() {
      window.open(this.patientLinkUrl, "_blank");
    },
    toNumber(a) {
      return toNumber(a);
    },
    editPaymentPlan() {
      this.showEditPlanDlg(true);
    },
    chargePayment() {
      this.showChargeDlg(true);
    },
    resendConsent() {
      this.loadConsents()
        .then(consents => consents[this.paymentPlan.id])
        .then(consent => {
          if (!consent) {
            return;
          }
          return this.showRequestConsentDlg(true).then(contact => [
            consent,
            contact
          ]);
        })
        .then(([consent, contact]) => {
          if (contact) {
            return this.resendConsentRequest({ consent, contact });
          } else {
            return null;
          }
        })
        .then(resp => {
          if (resp) {
            this.$toast.success("Request sent.");
          }
        })
        .catch(err => {
          global.console.log(err);
          this.$toast.error(err.error);
        });
    },
    formatStatus(status) {
      if (status === "not_subscribed") {
        return "Await consent";
      }
      return status;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/common.scss";

.u-mt {
  margin-top: 0.5em !important;
}

.u-mt2 {
  margin-top: 1em !important;
}

.container {
  .block {
    border-bottom: 1px solid $grey-10;
    padding-bottom: $gap-m-ppNew;
    margin-bottom: $gap-m-ppNew;

    .block-title {
      display: block;
      @extend .text-l-semibold;
      color: $black-100;
      margin-bottom: $gap-xs-ppNew;
    }

    .line {
      @extend .text-m-regular;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.5em;

      .line-subtitle {
        color: $grey-100;
      }

      .line-value {
        color: $black-100;
      }

      .contact-title {
        min-width: 70px;
      }

      .contact-value {
        text-align: right;
      }

      .balance-warning {
        color: $warning;
        font-size: 12px;
      }

      .icon-gap {
        margin-right: 0.5em;
        margin-bottom: 0.05em;
      }

      &.contacts {
        align-items: flex-start;

        .contact-item {
          display: block;
          margin-bottom: $gap-3xs-ppNew;

          &--disabled {
            color: $gray-1;
            text-decoration: line-through;
          }
        }
      }
    }
  }

  label {
    display: inline-block;
    padding: $gap-3xs-ppNew $gap-2xs-ppNew;
    font-size: 75%;
    font-weight: bold;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border-radius: 6px;
    background: $grey-10;
    color: $grey-100;
    text-transform: uppercase;

    &.is-primary {
      color: #ffffff;
      background: $primary;
    }
  }

  .vertical-separator {
    @extend .text-s-regular;
    color: $grey-30;
    padding: $gap-xs-ppNew;
  }
}

.is-disabled {
  cursor: not-allowed;
  color: $grey-30;
  pointer-events: none;
}
</style>
