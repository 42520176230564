<template>
  <div v-if="currentAppointment">
    <div class="display-flex align-center gap-2xs-ppNew">
      <div
        v-if="allowRedirectToAppointment"
        :class="{
          icon: true,
          'alert-icon': currentAppointment.hasIssues
        }"
      ></div>
      <div
        :class="{
          'text-l-semibold': true,
          apptsHeading: true,
          'is-success':
            allowRedirectToAppointment && !currentAppointment.hasIssues,
          'is-error': allowRedirectToAppointment && currentAppointment.hasIssues
        }"
      >
        Upcoming appointment
      </div>
    </div>

    <div class="margin-top-xs-ppNew text-m-semibold">
      {{ currentAppointment.location.name }}
    </div>

    <div
      class="margin-top-2xs-ppNew display-flex flex-column gap-2xs-ppNew text-m"
    >
      <div class="display-flex justify-space-between">
        <div>Provider</div>

        <div>
          {{ currentAppointment.provider.first_name }}
          {{ currentAppointment.provider.last_name }}
        </div>
      </div>

      <div class="display-flex justify-space-between">
        <div>Service date</div>

        <div>
          <a
            v-if="allowRedirectToAppointment"
            :href="currentAppointment.url"
            target="_blank"
            class="text-m-semibold blue-100-ppNew"
          >
            <span>
              {{ currentAppointment.formatted_service_date }}
            </span>
          </a>
          <span v-if="!allowRedirectToAppointment">
            {{ currentAppointment.formatted_service_date }}
          </span>
        </div>
      </div>

      <div
        v-if="currentAppointment.check_in"
        class="display-flex justify-space-between"
      >
        <div>Digital check-in status</div>

        <div
          :class="{
            'text-xs': true,
            status: true,
            [currentAppointment.check_in_display_status]: true
          }"
        >
          {{ currentAppointment.check_in_display_status.replaceAll("_", " ") }}
        </div>
      </div>

      <div v-if="kioskCode">
        <div class="display-flex justify-space-between">
          <div>Kiosk code</div>
          <div class="text-m-semibold">{{ kioskCode }}</div>
        </div>
      </div>

      <div
        v-if="shouldShowSendLinkButton"
        class="display-flex flex-column align-end"
      >
        <button
          type="button"
          class="tertiary-button text-m-semibold"
          @click="showSendCheckInLinkDialog(true)"
        >
          Send check-in link
        </button>

        <div v-if="lastSentAt">
          <div
            class="margin-top-3xs-ppNew display-flex justify-space-between text-xs"
          >
            <div>
              Last sent at
              {{ lastSentAt | formatDate("l LT", true) }}
              {{ currentAppointment.display_timezone }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isSelfPay" class="margin-top-2xs-ppNew">
      <div class="text-m-semibold">Self-pay visit</div>
      <div class="text-s-regular grey-100-ppNew margin-top-3xs-ppNew">
        The patient opted for self-pay
      </div>
    </div>

    <div class="margin-top-s-ppNew">
      <AppointmentInsurancesList
        :appointment="currentAppointment"
        :withViewBenefitsLink="allowRedirectToAppointment"
      />
    </div>

    <div
      v-if="allowRedirectToAppointment && currentAppointment.url"
      class="margin-top-2xs-ppNew"
    >
      <AppointmentIssues
        :display_issue_blocks="currentAppointment.check_in.display_issue_blocks"
        :apptUrl="currentAppointment.url"
      />
    </div>

    <div class="margin-top-2xs-ppNew">
      <PatientCheckInActions :appointment="currentAppointment" />
    </div>

    <div
      v-if="this.appointments.length > 1"
      class="margin-top-xs-ppNew display-flex justify-space-between"
    >
      <button
        v-if="this.currentAppointmentIndex !== 0"
        type="button"
        class="text-m-semibold blue-100-ppNew controlBtn"
        @click="moveBack"
      >
        <div class="icon caret-icon"></div>
        Previous
      </button>

      <button
        v-if="this.currentAppointmentIndex !== this.appointments.length - 1"
        type="button"
        class="text-m-semibold blue-100-ppNew controlBtn right"
        @click="moveForward"
      >
        Next
        <div class="icon caret-icon right"></div>
      </button>
    </div>

    <SendCheckInLinkDialog
      v-if="isSendLinkDialogOpen"
      :currentAppointment="currentAppointment"
    />
  </div>
</template>

<script>
import _findIndex from "lodash/findIndex";
import { mapState, mapActions } from "vuex";
import { webAppURL } from "@/constants";
import AppointmentIssues from "./AppointmentIssues";
import PatientCheckInActions from "./PatientCheckInActions";
import AppointmentInsurancesList from "./AppointmentInsurancesList";
import SendCheckInLinkDialog from "./SendCheckInLinkDialog";

export default {
  name: "AppointmentsSlider",
  components: {
    AppointmentIssues,
    PatientCheckInActions,
    AppointmentInsurancesList,
    SendCheckInLinkDialog
  },
  props: {
    appointments: {
      type: Array,
      required: true,
      validator: value => value && value.length > 0
    },
    isAppointmentPollingAllowed: {
      type: Boolean,
      required: true
    },
    targetExternalId: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      currentAppointmentIndex: 0,
      pollAppointmentTimeoutId: null
    };
  },
  created() {
    this.getCurrentCompanyFeatures();
    this.fetchCurrentCompany();

    document.addEventListener(
      "visibilitychange",
      this.windowVisibilityChangeListener
    );
  },
  destroyed() {
    document.removeEventListener(
      "visibilitychange",
      this.windowVisibilityChangeListener
    );
  },
  mounted() {
    const targetIndex = _findIndex(
      this.appointments,
      apt => apt.external_id === this.targetExternalId
    );

    if (targetIndex >= 0) {
      this.currentAppointmentIndex = targetIndex;
    }
  },
  beforeDestroy() {
    this.stopCurrentAppointmentPoll();
  },
  computed: {
    currentAppointment() {
      const current = this.appointments[this.currentAppointmentIndex];
      return {
        ...current,
        url: this.appointmentLinkUrl(current),
        formatted_service_date: this.formatServiceDate(
          current.service_date_start_local,
          current.display_timezone
        ),
        hasIssues: this.hasIssues(current)
      };
    },
    shouldShowSendLinkButton() {
      if (
        !this.currentAppointment ||
        !this.currentAppointment.check_in_display_status
      ) {
        return false;
      }

      return [
        "link_not_sent",
        "link_sent",
        "in_progress",
        "digitally_unreachable"
      ].includes(this.currentAppointment.check_in_display_status);
    },
    lastSentAt() {
      if (!this.currentAppointment || !this.currentAppointment.check_in) {
        return null;
      }

      const {
        last_link_sent_at_by_sms_local,
        last_link_sent_at_by_email_local
      } = this.currentAppointment.check_in;

      const sortedTimestamps = [
        last_link_sent_at_by_sms_local || null,
        last_link_sent_at_by_email_local || null
      ].sort((a, b) => new Date(b).getTime() - new Date(a).getTime());

      return sortedTimestamps[0] || null;
    },
    kioskCode() {
      if (!this.isKioskFeatureEnabled || !this.currentAppointment.check_in) {
        return null;
      }

      return this.currentAppointment.check_in.kiosk_code;
    },
    isSelfPay() {
      const { currentAppointment } = this;
      const insurancesObj =
        currentAppointment &&
        currentAppointment.check_in &&
        currentAppointment.check_in.insurances
          ? currentAppointment.check_in.insurances
          : {};

      return insurancesObj.is_self_pay || false;
    },
    ...mapState({
      isKioskFeatureEnabled: (_, getters) =>
        getters.isFeatureEnabled("pre-visit-kiosk-check-in"),
      allowRedirectToAppointment: (state, getters) => {
        const isPreVisitCheckInEnabled = getters.isFeatureEnabled(
          "pre-visit-check-in"
        );

        if (!state.patient) return false;

        const company = getters.getCurrentCompany(state.patient.company);

        if (!company) return false;

        return isPreVisitCheckInEnabled && !company.hide_appointments;
      },
      isSendLinkDialogOpen: state => state.dialogOpen.sendCheckInLinkDialog
    })
  },
  methods: {
    ...mapActions([
      "getAppointment",
      "getCurrentCompanyFeatures",
      "fetchCurrentCompany",
      "showSendCheckInLinkDialog"
    ]),
    appointmentLinkUrl(appointment) {
      const appRoute = "/appointments/" + appointment.id;
      return webAppURL + appRoute;
    },
    formatServiceDate(serviceDate, displayTimezone) {
      return `${this.$options.filters.formatDate(
        serviceDate,
        "MMM DD, YYYY, h:mm a",
        true
      )} ${displayTimezone}`;
    },
    moveBack() {
      if (this.currentAppointmentIndex === 0) return;
      this.currentAppointmentIndex -= 1;
      this.restartCurrentAppointmentPoll();
    },
    moveForward() {
      if (this.currentAppointmentIndex < this.appointments.length - 1) {
        this.currentAppointmentIndex += 1;
        this.restartCurrentAppointmentPoll();
      }
    },
    hasIssues(appointment) {
      return (
        appointment &&
        appointment.check_in &&
        appointment.check_in.display_issue_blocks &&
        appointment.check_in.display_issue_blocks.length > 0
      );
    },
    // Poll appointments to be up-to-date with resolved issues
    startCurrentAppointmentPoll() {
      const currentAppointment = this.currentAppointment;

      if (!currentAppointment) {
        return;
      }

      if (!this.hasIssues(currentAppointment)) {
        return;
      }

      function pollAppointment(appointmentId) {
        return this.getAppointment(appointmentId).then(appointment => {
          if (!appointment) {
            return;
          }

          const hasCurrentAppointmentChanged =
            appointmentId !== this.currentAppointment.id;
          const isBrowserTabActive = !document.hidden;

          if (
            !isBrowserTabActive ||
            hasCurrentAppointmentChanged ||
            !this.hasIssues(appointment) ||
            !this.allowRedirectToAppointment ||
            !this.isAppointmentPollingAllowed
          ) {
            return;
          }

          this.pollAppointmentTimeoutId = setTimeout(() => {
            pollAppointment.call(this, appointmentId);
          }, 3000);
        });
      }

      pollAppointment.call(this, currentAppointment.id);
    },
    stopCurrentAppointmentPoll() {
      if (!this.pollAppointmentTimeoutId) {
        return;
      }

      clearTimeout(this.pollAppointmentTimeoutId);
    },
    restartCurrentAppointmentPoll() {
      this.stopCurrentAppointmentPoll();
      this.startCurrentAppointmentPoll();
    },
    windowVisibilityChangeListener() {
      if (document.hidden) {
        this.stopCurrentAppointmentPoll();
      } else {
        this.startCurrentAppointmentPoll();
      }
    },
    listenToPollingPermissionUpdates(isAllowed, wasAllowed) {
      if (wasAllowed && !isAllowed) {
        this.stopCurrentAppointmentPoll();
      }

      if (!wasAllowed && isAllowed) {
        this.startCurrentAppointmentPoll();
      }
    }
  },
  watch: {
    // patient info tab visibility change listener
    isAppointmentPollingAllowed(isAllowed, wasAllowed) {
      this.listenToPollingPermissionUpdates(isAllowed, wasAllowed);
    },
    // company setting and features change listener
    // this prop has incorrect value on mount and got updated later on
    // we need to subscribe for this prop in order to start polling after component mount
    allowRedirectToAppointment(isAllowed, wasAllowed) {
      this.listenToPollingPermissionUpdates(isAllowed, wasAllowed);
    }
  }
};
</script>

<style lang="scss" scoped>
.status {
  padding: 2px 8px;
  display: flex;
  align-items: center;
  border: 1px solid currentColor;
  border-radius: 6px;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 0.15em;

  &.digitally_unreachable {
    color: $red-120;
  }

  &.completed {
    color: $green-120;
  }

  &.link_sent {
    color: $green-120;
    background: $green-20;
    border-color: $green-20;
  }

  &.link_not_sent,
  &.cancelled {
    color: $grey-100;
    background: $grey-10;
    border-color: $grey-10;
  }
}

.container {
  .block {
    .line {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.5em;
    }
  }
}

.apptsHeading {
  &.is-success {
    color: #009a71;
  }

  &.is-error {
    color: #c13854;
  }
}

.icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../assets/checkmark.svg");
}

.alert-icon {
  background-image: url("../assets/alert.svg");
}

.caret-icon {
  background-image: url("../assets/caret.svg");

  &.right {
    transform: rotate(180deg);
  }
}

.controlBtn {
  display: flex;
  align-items: center;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  transition: 0.2s ease;

  &.right {
    margin-left: auto;
  }
}
</style>
