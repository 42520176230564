<script>
import { webAppURL } from "@/constants";

export default {
  name: "PosPaymentState",
  props: {
    workflowResponse: null,
    isCancellingPosWorkflow: null,
    isWorkflowCancelled: null,
    isPaymentPending: null
  },
  data() {
    return {
      hasContentChanged: false,
      totalAmount: null,
      isFetchingReceipt: false,
      receiptError: null
    };
  },
  watch: {
    content: {
      handler(curr, prev) {
        if (
          curr.heading === prev.heading &&
          curr.description === prev.description
        )
          return;

        this.hasContentChanged = true;

        setTimeout(() => {
          this.hasContentChanged = false;
        }, 700);
      },
      deep: true
    }
  },
  computed: {
    content() {
      if (!this.workflowResponse) return null;

      if (
        this.isWorkflowCancelled ||
        this.workflowResponse.workflow_status === "cancelled"
      ) {
        return {
          icon: "ban",
          heading: "Payment Cancelled"
        };
      }

      if (
        this.workflowResponse.workflow_status === "created" ||
        this.workflowResponse.workflow_status === "pending"
      ) {
        return {
          icon: "circle-notch",
          iconColorClass: "blue-100-ppNew",
          heading: "Payment Processing",
          description:
            this.stepName || "Ask the patient to follow terminal prompts."
        };
      }

      if (this.workflowResponse.workflow_status === "success") {
        return {
          icon: "check-circle",
          iconColorClass: "green-100-ppNew",
          heading: "Payment Successful",
          description:
            this.getReceiptStepText(this.activeStep) ||
            "Patient completed the payment."
        };
      }

      if (this.workflowResponse.workflow_status === "error") {
        return {
          icon: "exclamation-circle",
          iconColorClass: "red-120-ppNew",
          heading: "Payment Error",
          description:
            (this.workflowResponse && this.workflowResponse.error_text) ||
            "An error occurred during payment processing."
        };
      }

      return null;
    },
    stepName() {
      const steps = this.workflowResponse.workflow_steps;

      if (
        !this.workflowResponse ||
        !this.activeStep ||
        !steps ||
        !steps.length
      ) {
        return null;
      }

      const statusToTextMap = {
        "pay-and-store-card": "Waiting for the patient payment",
        "sign-receipt": "Patient is signing the receipt",
        "select-card-type": "Patient is selecting card type",
        "store-card": "Storing card",
        "send-receipt": this.getReceiptStepText(this.activeStep)
      };

      if (this.workflowResponse.workflow_status === "success") {
        return null;
      }

      return statusToTextMap[this.activeStep.name] || null;
    },
    activeStep() {
      return this.getActiveWorkflowStep(this.workflowResponse.workflow_steps);
    },
    canPrintReceipt() {
      return !!(this.activeStep && this.activeStep.payment_token);
    },
    cancelButtonText() {
      if (
        !this.workflowResponse ||
        !this.workflowResponse.workflow_steps ||
        !this.workflowResponse.workflow_steps.length
      ) {
        return null;
      }

      if (
        this.isWorkflowCancelled ||
        this.workflowResponse.workflow_status === "cancelled"
      ) {
        return this.isCancellingPosWorkflow
          ? "Releasing the terminal..."
          : "Close";
      }

      if (this.isPaymentPending) {
        return this.isCancellingPosWorkflow
          ? "Cancelling..."
          : "Cancel transaction";
      }

      return this.isCancellingPosWorkflow
        ? "Releasing the terminal..."
        : "Close";
    }
  },
  methods: {
    onCancelClick() {
      this.$emit("cancelWorkflow");
    },
    getActiveWorkflowStep(workflowSteps) {
      const stepWithError = workflowSteps.find(
        step => !!step.error_text || step.status === "error"
      );

      if (stepWithError) {
        return stepWithError;
      }

      const stepInProgress = workflowSteps.find(
        step => step.status === "pending"
      );

      if (stepInProgress) {
        return stepInProgress;
      }

      const successStep = workflowSteps.findLast(
        step => step.status === "success"
      );

      if (successStep) {
        return successStep;
      }

      return workflowSteps[0] || null;
    },
    getReceiptStepText(activeStep) {
      if (!activeStep) return null;

      if (
        activeStep.status === "pending" &&
        activeStep.name === "send-receipt" &&
        !activeStep.chosen_option
      ) {
        return "Waiting for the patient to select how they want to receive their receipt.";
      }

      if (
        activeStep.name === "send-receipt" &&
        activeStep.chosen_option === "email" &&
        activeStep.status !== "success"
      ) {
        return "Patient is entering their email address to receive the receipt.";
      }

      if (activeStep.chosen_option === "email") {
        return `The receipt has been sent via email to ${activeStep.chosen_email}`;
      }

      if (activeStep.chosen_option === "print") {
        return "Patient requested a printed copy of their receipt.";
      }

      if (activeStep.chosen_option === "skip") {
        return 'Patient selected "No receipt".';
      }

      return null;
    },
    async getReceipt() {
      const patientId = this.workflowResponse.patient_id;
      const paymentToken = this.activeStep.payment_token;

      this.receiptError = null;

      try {
        this.isFetchingReceipt = true;

        const response = await fetch(
          `/api/v2/patients/${patientId}/invoice_link?pdf&entity_token=${paymentToken}`
        );

        const data = await response.json();

        if (!data || !data.preview_link) throw new Error("No receipt data");

        const urlParams = new URLSearchParams(data.preview_link);
        const receiptToken = urlParams.get("token");

        window.open(
          `${webAppURL}/print-receipt?token=${receiptToken}`,
          "_blank"
        );

        return data;
      } catch (err) {
        const errorText =
          err?.response?.data?.error_text ||
          "Error retrieving receipt. Please try again.";

        this.receiptError = errorText;

        // TODO: error handling, add the error to state and show it to the user
        return errorText;
      } finally {
        this.isFetchingReceipt = false;
      }
    }
  },
  created() {
    const paymentStep = this.workflowResponse.workflow_steps.find(
      s => s.name === "pay-and-store-card"
    );

    if (!paymentStep || !paymentStep.amount) return;

    this.totalAmount = paymentStep.amount;
  }
};
</script>

<template>
  <div class="stateWrapper" :class="{ contentChanged: hasContentChanged }">
    <div v-if="content">
      <div class="display-flex flex-column gap-s-ppNew">
        <div class="display-flex align-center gap-xs-ppNew">
          <font-awesome-icon
            :icon="content.icon"
            :spin="content.icon === 'circle-notch'"
            size="2x"
            :class="{
              'grey-100-ppNew': true,
              [content.iconColorClass]: !!content.iconColorClass,
              animateIcon: content.icon === 'check-circle'
            }"
          ></font-awesome-icon>

          <div>
            <div
              class="display-flex gap-3xs-ppNew text-m-semibold grey-100-ppNew"
            >
              <div v-if="totalAmount">{{ totalAmount | toUSD }}</div>
              <div v-if="totalAmount">-</div>
              <div>{{ content.heading }}</div>
            </div>

            <div
              v-if="content.description"
              class="text-m-medium grey-70-ppNew description"
            >
              {{ content.description }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <div>Error. No response receieved</div>
    </div>

    <div class="controls display-flex flex-column align-right gap-3xs-ppNew">
      <div
        class="margin-left-auto-ppNew display-flex align-center gap-xs-ppNew"
      >
        <button
          v-if="cancelButtonText"
          :class="{
            'text-m': true,
            'button cancel-button': true,
            cancelling: isCancellingPosWorkflow
          }"
          @click="onCancelClick"
        >
          <span v-if="isCancellingPosWorkflow" class="loader"></span>
          <span>{{ cancelButtonText }}</span>
        </button>

        <button
          v-if="canPrintReceipt"
          class="button text-m print-button"
          type="button"
          @click="getReceipt"
          :disabled="isFetchingReceipt"
        >
          <span v-if="isFetchingReceipt" class="loader"></span>

          <span>{{
            isFetchingReceipt ? "Getting Receipt..." : "Get Receipt"
          }}</span>
        </button>
      </div>
      <div class="text-s red-120-ppNew">{{ receiptError }}</div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.stateWrapper {
  position: relative;
  flex: 1 0 auto;
  display: grid;
  place-items: center;
  z-index: 2;
}

.contentChanged {
  animation: contentChanged 0.5s forwards ease;
}

.description {
  max-width: 360px;
  word-wrap: break-word;
}

.controls {
  position: absolute;
  bottom: 0;
  right: 0;
}

.button {
  display: flex;
  align-items: center;
  gap: $gap-2xs-ppNew;
  padding: $gap-3xs-ppNew $gap-xs-ppNew;
  cursor: pointer;
  user-select: none;
  font-family: "Inter", sans-serif;
  transition: background-color 0.2s, color 0.2s;
}

button.print-button {
  background: $blue-120;
  color: $white-100;
  border: 1px solid $blue-120;

  &:hover {
    color: $white-100;
    border: 1px solid $blue-120;
    background: $blue-180;
  }

  &:active {
    border-color: $white-100;
    color: $white-100;
  }
}

.cancel-button {
  border: 1px solid $blue-100;
  border-radius: 4px;
  font-weight: 500;
  background-color: transparent;

  &.cancelling {
    border-color: $grey-10;
    background: $grey-10;
    color: $grey-100;
    pointer-events: none;

    .loader {
      border-color: $blue-100;
      border-right-color: transparent;
      border-top-color: transparent;
    }
  }

  &:not(.cancelling) {
    &:hover {
      background-color: $blue-100;
      color: white;
    }

    &:active {
      background-color: $blue-120;
    }
  }
}

.animateIcon {
  opacity: 0;
  transform: rotate(30deg) scale(0.65);
  animation: iconMove 0.75s ease forwards;
  animation-delay: 0.2s;
}

@keyframes iconMove {
  from {
    opacity: 0;
    transform: rotate(30deg) scale(0.65);
  }
  to {
    opacity: 1;
    transform: rotate(0deg) scale(1);
  }
}

@keyframes contentChanged {
  from {
    transform: translateY(-10px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
</style>
