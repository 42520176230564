var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"padding-s-ppNew bg-grey-5-ppNew border-radius-2xs-ppNew"},[(
      _vm.appointment.check_in_display_status === 'in_progress' ||
        _vm.appointment.check_in_display_status === 'completed' ||
        _vm.appointment.check_in_display_status === 'cancelled'
    )?_c('div',{staticClass:"text-m-semibold grey-100-ppNew"},[_vm._v(" "+_vm._s(_vm.actions && _vm.actions.length ? "Patient completed the following actions during digital check-in:" : "Patient has not completed any actions during digital check-in.")+" ")]):_c('div',{staticClass:"text-m-semibold grey-100-ppNew"},[_vm._v(" Patient has not started digital check-in yet ")]),(_vm.actions && _vm.actions.length)?_c('ul',{staticClass:"actions margin-top-2xs-ppNew padding-left-s-ppNew grey-100-ppNew"},[_vm._l((_vm.shouldShowAllActions
        ? _vm.actions
        : _vm.actions.slice(0, 4)),function(action,index){return _c('li',{key:index,staticClass:"listItem"},[_c('span',[_vm._v(_vm._s(action.name))]),(typeof action.value === 'string')?_c('span',{staticClass:"text-m-semibold margin-left-3xs-ppNew"},[_vm._v(_vm._s(action.value))]):_vm._e(),(typeof action.tooltip === 'string')?_c('span',{staticClass:"margin-left-3xs-ppNew"},[_c('div',{staticClass:"customTooltip"},[_c('font-awesome-icon',{staticClass:"blue-100-ppNew",attrs:{"icon":"question-circle"}}),_c('div',{staticClass:"tooltipContent"},[_c('div',{staticClass:"text-m"},[_vm._v(" "+_vm._s(action.tooltip)+" ")])])],1)]):_vm._e(),(Array.isArray(action.tooltip))?_c('span',{staticClass:"margin-left-3xs-ppNew"},[_c('div',{staticClass:"customTooltip"},[_c('font-awesome-icon',{staticClass:"blue-100-ppNew",attrs:{"icon":"question-circle"}}),_c('div',{staticClass:"tooltipContent"},[_c('div',{staticClass:"text-m"},[_vm._v(" "+_vm._s(action.tooltip.length && action.tooltip .map(s => s[0].toUpperCase() + s.slice(1)) .join(", "))+" ")])])],1)]):_vm._e()])}),(_vm.actions.length > 4)?_c('div',{staticClass:"margin-top-3xs-ppNew text-m-semibold blue-100-ppNew toggleBtn",on:{"click":function($event){return _vm.toggleShowAllActions()}}},[_vm._v(" Show "+_vm._s(_vm.shouldShowAllActions ? "less" : "more")+" ")]):_vm._e()],2):_vm._e(),(_vm.shouldShowNote)?_c('div',{staticClass:"margin-top-xs-ppNew text-s grey-70-ppNew"},[_c('span',{staticClass:"text-s-semibold"},[_vm._v("Note: ")]),_vm._v(" changes to the patient check-in may not be reflected in the EHR immediately ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }