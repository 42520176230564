<script>
export default {
  name: "ContactItem",
  props: {
    contact: {
      type: Object,
      required: true
    },
    selectedContactId: {
      type: [Number]
    }
  },
  data() {
    return {
      phoneTypes: {
        1: "Mobile",
        2: "Landline",
        3: "VoIP"
      },
      priorityTextMap: {
        primary: "Patient primary",
        secondary: "Patient secondary",
        work: "Patient work",
        guarantor: "Guarantor",
        guarantor_work: "Guarantor work"
      }
    };
  },
  computed: {
    mappedItem() {
      return {
        ...this.contact,
        value: this.contact.email || this.contact.phone,
        contactType: this.contact.email ? "email" : "phone"
      };
    },
    isSelected() {
      return this.selectedContactId === this.contact.id;
    },
    contactDescription() {
      const allPriorities = this.mappedItem.all_priorities;

      if (!allPriorities || !allPriorities.length) {
        return null;
      }

      const descriptionItems = allPriorities
        .map(priority => {
          if (!priority || !this.priorityTextMap[priority]) return null;

          return `${this.priorityTextMap[priority]} ${this.mappedItem.contactType}`;
        })
        .filter(Boolean);

      const phoneType =
        this.mappedItem.type !== undefined &&
        this.phoneTypes[this.mappedItem.type]
          ? ` (${this.phoneTypes[this.mappedItem.type]})`
          : "";

      return `${descriptionItems.join(", ")}${phoneType}`;
    }
  },
  methods: {
    handleClick() {
      this.$emit("selectContact");
    }
  }
};
</script>

<template>
  <div
    @click="handleClick()"
    :class="{
      'padding-x-s-ppNew padding-y-xs-ppNew display-flex gap-xs-ppNew contact': true,
      selected: isSelected
    }"
  >
    <div class="icon-container">
      <svg
        v-if="mappedItem.contactType === 'phone'"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.6665 1.99984C4.29831 1.99984 3.99984 2.29831 3.99984 2.6665V13.3332C3.99984 13.7014 4.29831 13.9998 4.6665 13.9998H11.3332C11.7014 13.9998 11.9998 13.7014 11.9998 13.3332V2.6665C11.9998 2.29831 11.7014 1.99984 11.3332 1.99984H4.6665ZM2.6665 2.6665C2.6665 1.56193 3.56193 0.666504 4.6665 0.666504H11.3332C12.4377 0.666504 13.3332 1.56193 13.3332 2.6665V13.3332C13.3332 14.4377 12.4377 15.3332 11.3332 15.3332H4.6665C3.56193 15.3332 2.6665 14.4377 2.6665 13.3332V2.6665Z"
          fill="#ACB5F6"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.33317 11.9998C7.33317 11.6316 7.63165 11.3332 7.99984 11.3332H8.0065C8.37469 11.3332 8.67317 11.6316 8.67317 11.9998C8.67317 12.368 8.37469 12.6665 8.0065 12.6665H7.99984C7.63165 12.6665 7.33317 12.368 7.33317 11.9998Z"
          fill="#ACB5F6"
        />
      </svg>

      <svg
        v-else
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.81233 4.2398L8.00016 7.87128L13.188 4.2398C13.0915 4.04904 12.8931 3.91667 12.6668 3.91667H3.3335C3.10719 3.91667 2.9088 4.04904 2.81233 4.2398ZM13.2502 5.62038L8.33468 9.06122C8.13383 9.20182 7.8665 9.20182 7.66564 9.06122L2.75016 5.62038V11.5C2.75016 11.8195 3.014 12.0833 3.3335 12.0833H12.6668C12.9863 12.0833 13.2502 11.8195 13.2502 11.5V5.62038ZM1.5835 4.5C1.5835 3.53617 2.36966 2.75 3.3335 2.75H12.6668C13.6307 2.75 14.4168 3.53617 14.4168 4.5V11.5C14.4168 12.4638 13.6307 13.25 12.6668 13.25H3.3335C2.36966 13.25 1.5835 12.4638 1.5835 11.5V4.5Z"
          fill="#ACB5F6"
        />
      </svg>
    </div>

    <div>
      <div class="text-m-medium">{{ mappedItem.value }}</div>

      <div
        class="margin-top-2xs-ppNew display-flex align-center gap-3xs-ppNew text-s grey-100-ppNew"
        v-if="contactDescription"
      >
        {{ contactDescription }}
      </div>
    </div>

    <div
      class="margin-left-auto-ppNew display-flex align-center justify-center select-state"
    >
      <svg
        v-if="isSelected"
        width="10"
        height="9"
        viewBox="0 0 10 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.52551 0.848565C9.87698 1.20004 9.87698 1.76989 9.52551 2.12136L3.23226 8.41461L0.474541 5.65689C0.12307 5.30542 0.12307 4.73557 0.474541 4.3841C0.826013 4.03263 1.39586 4.03263 1.74733 4.3841L3.23226 5.86902L8.25272 0.848565C8.60419 0.497093 9.17404 0.497093 9.52551 0.848565Z"
          fill="white"
        />
      </svg>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.icon-container {
  width: 16px;
  height: 16px;
}

.contact {
  cursor: pointer;
  border: 1px solid $blue-20;
  border-radius: 6px;
  transition: background-color 0.15s ease;

  &:hover {
    background-color: $blue-10;
  }

  &.selected {
    background-color: $blue-20;
    border-color: $blue-120;
  }
}

.select-state {
  padding: 4px 3px;
  width: 16px;
  height: 16px;
  border: 1px solid $blue-50;
  border-radius: 50%;

  .selected & {
    background-color: $blue-120;
  }
}
</style>
